<template>
    <div class="Navbar">
        <img src="../assets/logoabsl+.png" alt="">
        <nav>
            <router-link to="/">Accueil</router-link>
            <router-link to="/prestations">Nos prestations</router-link>
            <router-link to="/Pourquoi-choisir-absl">Pourquoi choisir ABSL ?</router-link>
            <router-link to="/Témoinages">Témoinages</router-link>
            <router-link to="/contact">Contact</router-link>
        </nav>
    </div>
</template>

<script>
export default {
  name: 'Navbar',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .Navbar {
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
    nav {
        margin-right: 50px;
    }
    img {
        height: 50%;
        margin-left: 50px;
    }
  a {
      font-weight: bold;
      color: #2c3e50;
      text-decoration: none;
      margin-right: 15px;
      transition: .3s all ease-in-out;

      &:hover {
            color: #6c869e;
        }

      &.router-link-exact-active {
        color: #3c97f7;
      }
    }
</style>
