<template>
  <div id="app">
    <Navbar v-if="!mobileView" />
    <MobileNav v-if="mobileView" />
    <transition name="fade" mode="out-in">
      <router-view/>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import MobileNav from '@/components/NavBarMobile.vue'

export default {
  name: 'Home',
  components: {
    Navbar,
    MobileNav
  },
  data() {
    return {
        mobileView: false,
    }
  },
  methods: {
    handleView() {
      this.mobileView = window.innerWidth <= 850;
    }
  },
  created() {
    this.handleView();
    window.addEventListener('resize', this.handleView);
  }
}
</script>

<style lang="scss">
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
    transform: translateX(2em);
  }
  .fade-enter-active, .fade-leave-active {
    transition: all .3s ease;
  }
  .solution {
       text-align: center;
       margin-bottom: 100px;
       list-style-type: none;
       h2 {
           color: #3c97f7;
           margin-bottom: 20px;
       }
       li {
           padding: 10px 0;
           font-weight: 500;
       }
   }
   .devise {
      font-size: 20px;
      font-weight: 600;
      color: #3c97f7;
      text-align: center;
      margin-bottom: 50px;
  }
</style>
