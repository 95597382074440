import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/contact',
    name: 'Contact',
    
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/Pourquoi-choisir-absl',
    name: 'Pourquoi-choisir-absl',
    
    component: () => import('../views/Qui-sommes-nous.vue')
  },
  {
    path: '/prestations',
    name: 'prestations',
    
    component: () => import('../views/Prestations.vue')
  },
  {
    path: '/Securisation',
    name: 'Securisation',
    
    component: () => import('../views/Securisation.vue')
  },
  {
    path: '/Alarmes-Video-protection',
    name: 'Alarmes-Video-protection',
    
    component: () => import('../views/Alarmes.vue')
  },
  {
    path: '/Tolage-Murage',
    name: 'Tolage-Murage',
    
    component: () => import('../views/Tolage.vue')
  },
  {
    path: '/Nettoyage-Elagage',
    name: 'Nettoyage-Elagage',
    
    component: () => import('../views/Elagage.vue')
  },
  {
    path: '/Gardiennage',
    name: 'Gardiennage',
    
    component: () => import('../views/Gardiennage.vue')
  },
  {
    path: '/Témoinages',
    name: 'Témoinages',
    
    component: () => import('../views/Témoinages.vue')
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
