<template>
    <div class="footer">
        <div class="footer__logo" v-scroll-reveal="{ duration: 1200, delay: 100, origin: 'bottom', distance: '20px'}">
            <img src="@/assets/logoabsl+.png" alt="logo de la société">
        </div>
        <div class="footer__informations" v-scroll-reveal="{ duration: 1200, delay: 300, origin: 'right', distance: '20px'}">
            <p>ABSL – 3 sites en Ile-de-France</p>
            <p>09.67.08.53.58</p>
            <p>contact@abslfrance.fr</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style scoped>
    .footer {
        bottom: 0;
        width: 100%;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFF;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 0px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
    .footer__logo {
        width: 200px;
        height: auto;
        margin: 15px;
    }
    .footer__logo img {
        width: 100%;
        height: auto;;
    }
    .footer__informations p {
        font-size: 14px;
        font-weight: 600;
        color: #3c97f7;
        margin: 15px;
    }
</style>