<template>
    <div class="home">
        <Carousel>
            <Carousel-slide style="opacity: 1">
                <div class="overlay"></div>
                <img src="@/assets/slider6.jpg" alt="salon">
                <div class="text-container">
                    <div class="slide__text">
                        <h2>Portes blindées / Panneaux anti-intrusion</h2> 
                        <router-link to="/Securisation" class="ESP">En savoir plus</router-link>
                    </div>
                </div>
            </Carousel-slide>
            <Carousel-slide style="opacity: 1">
                <div class="overlay"></div>
                <img src="@/assets/Alarme.png" alt="terasse avec baie vitrée">
                <div class="text-container">
                    <div class="slide__text">
                        <h2>Alarmes / Vidéo-protection / Télésurveillance</h2>
                        <router-link to="/Alarmes-Video-protection" class="ESP">En savoir plus</router-link>
                   </div>
                </div>
            </Carousel-slide>
            <Carousel-slide style="opacity: 1">
                <div class="overlay"></div>
                <img src="@/assets/murage.jpg" alt="enfants">
                <div class="text-container">
                    <div class="slide__text">
                        <h2>Tôlage / Murage / Bardage</h2>
                        <router-link to="/Tolage-Murage" class="ESP">En savoir plus</router-link>
                    </div>
                </div>
            </Carousel-slide>
            <Carousel-slide style="opacity: 1">
                <div class="overlay"></div>
                <img src="@/assets/elagage.jpg" alt="télécommande tactile">
                <div class="text-container">
                    <div class="slide__text">
                        <h2>Nettoyage / Elagage / Démolition</h2>
                        <router-link to="/Nettoyage-Elagage" class="ESP">En savoir plus</router-link>
                    </div>
                </div>
            </Carousel-slide>
            <Carousel-slide style="opacity: 1">
                <div class="overlay"></div>
                <img src="@/assets/gardiennage.jpg" alt="télécommande tactile">
                <div class="text-container">
                    <div class="slide__text">
                        <h2>Gardiennage</h2>
                        <router-link to="/Gardiennage" class="ESP">En savoir plus</router-link>
                    </div>
                </div>
            </Carousel-slide>
        </Carousel>
        <div class="introduction">
            <p>ABSL 25 ans d'experience, société certifiée CNAPS, est a votre écoute et à votre service pour vous proposer, selons vos besoins, diverses prestations afin de protéger, sécuriser vos biens, qu'ils soient privés, individuels et collectifs ou professionnels</p>
            <img src="@/assets/cnaps.png" alt="">
        </div>
        <Footer />
    </div>
</template>

<script>
import Carousel from '@/components/carousel/Carousel.vue'
import CarouselSlide from '@/components/carousel/CarouselSlide.vue'
import Footer from '@/components/Footer.vue'

export default {
    components: {
        Carousel,
        CarouselSlide,
        Footer
  }
}
</script>

<style scoped lang="scss">
@media screen and (min-width: 800px) {
    .carousel img {
        width: 100%;
    }
}
    .overlay { 
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5); 
        left: 0;
        top: 0;
    } 
    .text-container {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }   
    .slide__text {
        width: fit-content;
        text-align: center;
    }
    .slide__text h2 {
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
        text-transform: uppercase;
        color: #3c97f7;
        font-weight: 900;
        font-size: 38px;
        margin-bottom: 25px;
    }
    .slide__text .ESP {
        text-decoration: none;
        color: #FFF;
        font-weight: 900;
        text-transform: uppercase;
        border: 2px solid #fff;
        border-radius: 8px;
        padding: 12px 15px;
        transition: .2s all ease-in-out;

        &:hover {
            border: 2px solid #3c97f7;
            color: #3c97f7;
        }
    }
    .introduction {
        width: 100%;
        text-align: center;
        margin-top: 50px;
        margin-bottom: 50px;

        p {
            width: 75%;
            margin: auto;
            font-size: 20px;
            font-weight: 500;
            color: grey;
        }
        img {
            width: 5%;
            margin-top: 50px;
        }
    }
    @media screen and (max-width: 650px) {
        .slide__text {
            width: 400px;
        }
        .slide__text h2 {
            font-size: 30px;
        }
        .slide__text p {
            font-size: 20px;
        }
    }
    @media screen and (max-width: 499px) {
        .slide__text {
            width: 300px;
        }
        .slide__text h2 {
            font-size: 25px;
        }
        .slide__text p {
            font-size: 18px;
        }
    }
</style>