<template>
  <div class="Mobile_nav">
    <div class="header_bar">
        <img src="../assets/logoabsl.png" alt="Logo entreprise">
        <img src="@/assets/menu.png" alt="icon menu hamburger" @click="showNav = !showNav">
    </div>
    <nav @click="showNav = !showNav" :class="{'open':showNav}">
        <router-link to="/">Accueil</router-link>
        <router-link to="/prestations">Nos prestations</router-link>
        <router-link to="/Pourquoi-choisir-absl">Qui-sommes-nous ?</router-link>
        <router-link to="/Témoinages">Témoinages</router-link>
        <router-link to="/contact">Contact</router-link>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Mobile_navMobile',
  data() {
      return {
          showNav: false
      }
  }
}
</script>

<style scoped>
  .Mobile_nav {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
    z-index: 100;
  }
  .header_bar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    z-index: 1;
  }
  .header_bar img:first-child {
    width: 100px;
    height: auto;
  }
  .header_bar img:nth-child(2) {
    width: 20px;
    height: auto;
    cursor: pointer;
  }
  .Mobile_nav nav {
    position: fixed;
    width: 100vw;
    height: 100vh;
    max-height: 0;
    top: 0;
    padding: 0 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    transition: all 0.4s ease-in-out;
    overflow: hidden;
    visibility: hidden;
  }
  .Mobile_nav nav a {
    color: #3c97f7;
    font-size: 35px;
    font-weight: 600;
    text-decoration: none;
    padding: 20px 5%;
  }
  .Mobile_nav nav a:hover {
    opacity: 0.7;
    transition: ease-in-out 0.3s;
  }
  .Mobile_nav nav a.router-link-exact-active {
   color: #126451;
  }
  .Mobile_nav .open {
    visibility: visible;
    max-height: 100vh;
    padding: 3%;
  }
  @media screen and (max-width: 499px) {
    .Mobile_nav nav a {
    font-size: 25px;
  }
  }
</style>
